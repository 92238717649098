/* ScrollToTop.css */
.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #0099cc;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 24px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }
  
  .scroll-to-top:hover {
    background-color: #0099cc;
  }
  .scroll-to-top1 {
    position: fixed;
    bottom: 40px;
    left: 50px;
    background-color: green;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 24px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }
  